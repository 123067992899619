<template>
  <div>
    <div class="flex mb-4 justify-between">
      <h1 class="font-medium text-3xl">{{ $t("carriage_types") }}</h1>
      <button
        v-if="role != 99"
        @click="open = true"
        class="bg-green-500 px-3 rounded text-gray-200"
      >
        <i class="el-icon-plus"></i> {{ $t("add") }}
      </button>
    </div>
    <div class="flex">
      <div class="flex-1 overflow-hidden">
        <el-table
          :data="carriageTypes"
          border
          v-loading="loading"
          style="width: 100%"
          class="rounded-lg shadow-sm border"
        >
          <el-table-column prop="id" align="center" label="ID" width="60">
          </el-table-column>
          <el-table-column prop="name" :label="$t('name')" min-width="200">
          </el-table-column>
          <el-table-column :label="$t('photo')" min-width="220" align="center">
            <template slot-scope="scope">
              <div class="h-20 2xl:h-32">
                <img
                  class="h-full w-full object-contain"
                  :src="scope.row.photo"
                  :alt="scope.row.photo"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('actions')"
            fixed="right"
            align="center"
            :min-width="actionsWidth"
            v-if="role != 99"
          >
            <template slot-scope="scope">
              <el-button size="mini" @click="openEdit(scope.row.id)">
                <i class="el-icon-edit"></i>
                <span class="hidden lg:inline-block">{{ $t("edit") }}</span>
              </el-button>
              <el-popconfirm
                :confirm-button-text="$t('yes')"
                :cancel-button-text="$t('no_thanks')"
                icon="el-icon-info"
                icon-color="red"
                :title="$t('you_want_to_delete')"
                class="ml-2"
                @confirm="CarriageTypeDelete(scope.row.id)"
              >
                <el-button slot="reference" size="mini" type="danger">
                  <i class="el-icon-delete"></i>
                  <span class="hidden lg:inline-block">{{ $t("delete") }}</span>
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="mt-3 flex justify-end">
          <el-pagination
            background
            layout="prev, pager, next"
            hide-on-single-page
            @prev-click="Prev"
            @next-click="Next"
            @current-change="handleCurrentChange"
            :total="totalCount"
          >
          </el-pagination>
        </div>
      </div>
      <el-drawer
        :visible.sync="open"
        :with-header="false"
        size="400px"
        @close="CloseDrawer"
        v-if="role != 99"
      >
        <div
          class="h-20 bg-111E52 flex items-center justify-between px-3 text-white text-xl"
        >
          <p>EXSERVICE</p>
          <button @click="open = false" class="px-2">
            <i class="el-icon-close"></i>
          </button>
        </div>
        <div class="p-4">
          <h1 class="mb-4">{{ $t("add_carriage_type") }}</h1>
          <el-form
            ref="carriage_type"
            label-position="top"
            :model="carriage_type"
            @submit.prevent.native
          >
            <el-form-item
              prop="name"
              :label="$t('name')"
              :rules="{
                required: true,
                message: $t('please_input'),
                trigger: 'blur',
              }"
            >
              <el-input
                :placeholder="$t('input')"
                v-model="carriage_type.name"
                clearable
              >
              </el-input>
            </el-form-item>

            <div
              v-if="photoUrl"
              class="mb-2 flex justify-center border border-gray-300 rounded p-1 shadow-sm"
            >
              <img class="max-w-full object-contain" :src="photoUrl" alt="" />
            </div>
            <input type="file" class="hidden" id="photo" @change="addFile" />
            <label
              for="photo"
              class="cursor-pointer border border-gray-500 bg-gray-50 shadow-sm px-3 py-1 rounded text-gray-500"
              >{{ $t("choose_image") }}</label
            >
          </el-form>

          <div class="text-right">
            <el-button
              v-if="!edit"
              @click="addCarriageType"
              type="primary"
              :loading="loading"
            >
              {{ $t("add") }}
            </el-button>
            <el-button
              v-else
              @click="editCarriageType"
              type="primary"
              :loading="loading"
            >
              {{ $t("save") }}
            </el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "carriagreType",
  data() {
    return {
      actionsWidth: 270,
      open: false,
      edit: false,
      carriage_type: {
        photo: "",
        name: "",
      },
      photoUrl: "",
      id: "",
      carriageTypes: [],
      totalCount: 0,
      count: 1,
      loading: false,
      role: localStorage.role,
    };
  },
  created() {
    this.getCarriageTypes();
    window.innerWidth < 992 ? (this.actionsWidth = 120) : "";
  },
  methods: {
    async getCarriageTypes() {
      this.loading = true;
      await axios
        .get(`carriage_types/${this.count === 1 ? "" : "?page=" + this.count}`)
        .then((response) => {
          this.totalCount = response.data.count;
          this.carriageTypes = response.data.results;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    Prev() {
      this.count--;
      this.getCarriageTypes();
    },
    handleCurrentChange(val) {
      this.count = val;
      this.getCarriageTypes();
    },
    Next() {
      this.count++;
      this.getCarriageTypes();
    },
    CloseDrawer() {
      this.edit = false;
      this.carriage_type.name = "";
      this.clearFile();
      this.$refs.carriage_type.clearValidate();
    },
    addFile(e) {
      this.carriage_type.photo = e.target.files[0];
      this.photoUrl = URL.createObjectURL(this.carriage_type.photo);
    },
    clearFile() {
      this.carriage_type.photo = "";
      this.photoUrl = "";
      document.getElementById("photo").value = "";
    },
    openEdit(id) {
      this.open = true;
      this.edit = true;
      this.id = id;
      let data = this.carriageTypes.find((data) => data.id === id);
      this.carriage_type.name = data.name;
      this.photoUrl = data.photo;
    },
    editCarriageType() {
      this.$refs.carriage_type.validate((valid) => {
        if (valid) {
          const formData = new FormData();
          this.carriage_type.photo
            ? formData.append("photo", this.carriage_type.photo)
            : "";
          this.carriage_type.name
            ? formData.append("name", this.carriage_type.name)
            : "";
          this.loading = true;
          axios
            .patch(`carriage_types/${this.id}/`, formData)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getCarriageTypes();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_changed"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("data_unchanged"),
              });
            });
        }
      });
    },
    addCarriageType() {
      this.$refs.carriage_type.validate((valid) => {
        if (valid) {
          if (this.carriage_type.photo) {
            this.loading = true;
            const formData = new FormData();
            formData.append("photo", this.carriage_type.photo);
            formData.append("name", this.carriage_type.name);
            axios
              .post("carriage_types/", formData)
              .then(() => {
                this.loading = false;
                this.open = false;
                this.getCarriageTypes();
                this.$notify({
                  title: this.$t("success"),
                  message: this.$t("data_added"),
                  type: "success",
                });
              })
              .catch(() => {
                this.loading = false;
                this.$notify.error({
                  title: this.$t("error"),
                  message: this.$t("no_data_added"),
                });
              });
          } else {
            this.$notify.error({
              title: this.$t("error"),
              message: this.$t("no_image"),
            });
          }
        }
      });
    },
    async CarriageTypeDelete(id) {
      this.loading = true;
      await axios
        .delete(`carriage_types/${id}/`)
        .then(() => {
          this.totalCount % 10 === 1 && this.totalCount / 10 >= 1
            ? this.count--
            : "";
          this.loading = false;
          this.getCarriageTypes();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
